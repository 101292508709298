<template>
  <div>
    <InquiryList />
  </div>
</template>

<script>
import InquiryList from "../../components/InquiryList/InquiryListInactive.vue";
export default {
  name: "inquiryListInactiveOrCompleted",
  components: { InquiryList },
};
</script>
