<template>
  <v-container id="inquiries" fluid tag="section">
    <v-row>
      <v-col cols="12">
        <v-container class="py-0">
          <form novalidate @submit.prevent="getInquiries" v-if="!loading">
            <md-card-content class="search-content">
              <v-row class="inquiry-search-bar">
                <v-col xl="3" lg="3" xs="6">
                  <md-field class="search_field_class">
                    <label for="search-query">Search</label>
                    <md-input
                      v-on:input="debounceSearch"
                      name="search"
                      id="search"
                      v-model="query"
                    />
                  </md-field>
                </v-col>
                <v-col xl="3" lg="3" xs="6">
                  <md-field class="search_field_class">
                    <label for="product_type">Product Type</label>
                    <md-select
                      @input="getInquiries"
                      data-cy="product-type"
                      name="product-type"
                      id="product-type"
                      v-model="productType"
                      placeholder="Product Type"
                      :disabled="loading"
                    >
                      <div class="search_type_container">
                        <md-option
                          class="list_color"
                          v-for="productType in productTypes"
                          :key="'producttype-' + productType.id"
                          :value="productType.id"
                          >{{ productType.name }}</md-option
                        >
                      </div>
                    </md-select>
                  </md-field>
                </v-col>
                <v-col
                  xl="3"
                  lg="3"
                  xs="6"
                  v-if="getIsAccountManager() || getIsCustomer()"
                >
                  <md-field class="search_field_class">
                    <label for="inquiry_type">Styles</label>
                    <md-select
                      @input="getInquiries"
                      data-cy="inquiry-type"
                      name="inquiry-type"
                      id="inquiry-type"
                      v-model="inquiryStatus"
                      placeholder="Styles"
                      :disabled="loading"
                    >
                      <div class="search_type_container">
                        <md-option value="completed" class="list_color">Completed</md-option>
                        <md-option value="inactive" class="list_color">Inactive</md-option>
                        <md-option value="pending_deleted_approval" class="list_color">Pending Deleted Approval</md-option>
                      </div>
                    </md-select>
                  </md-field>
                </v-col>
                <v-col lg="3" xs="6">
                  <v-btn
                    class="ma-2 mt-3 color_class"
                    dark
                    outlined
                    id="clear-filters"
                    :disabled="loading"
                    @click="clearFilters"
                  >
                    Clear filters
                  </v-btn>
                </v-col>
              </v-row>
            </md-card-content>
          </form>
          <v-row class="inquiry-list" v-if="!loading">
            <v-col
              cols="12"
              v-for="inquiry in inquiries"
              :key="inquiry.style_number"
              class="inquiry"
            >
              <router-link :to="'/app/inquiry/' + inquiry.id">
                <div class="md-layout-item">
                  <md-card class="inquiry-list__card">
                    <div class="header">
                      <div class="md-layout paddings">
                        <div class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100">
                          {{ inquiry.style_number }}
                        </div>
                        <div class="inquiry-style-number font-weight-bold md-size-25 md-xsmall-size-100">
                          {{ inquiry.product_name }}
                        </div>
                        <div class="inquiry-product-type font-weight-bold md-size-25 md-xsmall-size-100">
                          {{ getProductTypeName(inquiry.product_type) }}
                        </div>
                        <div class="date md-size-25 md-xsmall-size-100">
                          <span>
                            Created at
                            <span class="font-weight-bold"
                              >{{
                                $moment(inquiry.created_at).format(
                                  "HH:mm Do MMMM Y"
                                )
                              }}</span
                            >
                          </span>

                          <span
                            v-if="inquiry.created_at !== inquiry.updated_at"
                          >
                            <span>, </span>
                            Updated at
                            <span class="font-weight-bold">{{
                              $moment(inquiry.updated_at).format(
                                "HH:mm Do MMMM Y"
                              )
                            }}</span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <md-card-content class="card-content">
                      <div class="md-layout md-gutter" style="padding: 0px 50px;">
                        <div
                          class="md-layout-item md-size-10 md-xsmall-size-100 alignment seperator img-container"
                        >
                          <div class="embed">
                            <embed
                              class="centered-and-cropped"
                              :src="
                                inquiry.thumbnail &&
                                isSupportedFileType(
                                  inquiry.thumbnail.upload_name
                                )
                                  ? `${baseUrl}/api/inquiries/file_show/${inquiry.thumbnail.upload_name}`
                                  : sampleImage
                              "
                              alt="sample image"
                            />
                          </div>
                        </div>
                        <div
                          class="md-layout-item  md-size-20 md-xsmall-size-100 seperator card-content-item"
                        >
                          <div class="inquiry-quantity mt-1">
                            <div class="inquiry-quantity">
                              <span class="inquiry-sub-title">Qty: </span>
                              <span class="inquiry-quantity__value">{{
                                  inquiry.bulk_order_quantity
                              }}</span>
                            </div>
                            <div class="inquiry-colors mt-2">
                              <span class="inquiry-sub-title">
                                {{
                                  inquiry.colour_number > 1 ? "Colours:" : "Colour:"
                                }}
                              </span>
                              <span class="inquiry-quantity__value">{{
                                  inquiry.colour_number
                                }}</span>
                            </div>
                          </div>
                        </div>
                        <div
                          class="md-layout-item md-size-30 md-xsmall-size-100 seperator card-content-item"
                        >
                          <div class="inquiry-quantity mt-1">
                            <span class="inquiry-sub-title">Buyer: </span>
                            <span class="inquiry-quantity__value">{{
                              inquiry.customer.full_name
                            }}</span>
                          </div>
                        </div>
                        <div
                          class="md-layout-item md-size-25 md-xsmall-size-100 card-content-item"
                        >
                          <div class="inquiry-category inquiry-quantity mt-1">
                            <span class="inquiry-sub-title">Product Type: </span>
                            <span class="inquiry-quantity__value">{{
                              getProductTypeName(inquiry.product_type)
                            }}</span>
                          </div>
                          <div class="inquiry-material inquiry-quantity mt-2">
                            <span class="inquiry-sub-title">Material: </span>
                            <span class="inquiry-quantity__value">
                              {{ getMaterialName(inquiry.materials) }}
                            </span>
                          </div>
                        </div>
                        <div
                          class="md-layout-item md-size-10 md-xsmall-size-100"
                        >
                          <div v-if="inquiry.count > 0" class="text-left mt-4">
                            <v-badge
                              color="teal accent-4"
                              :content="inquiry.count"
                            >
                              <v-icon large color="teal accent-4">
                                mdi-message-text
                              </v-icon>
                            </v-badge>
                          </div>

                          <div v-if="inquiry.status === 'pending_deleted_approval'" class="text-left mt-4">
                              <v-icon large color="red lighten-1">
                                mdi-delete-clock
                              </v-icon>
                          </div>

                        </div>
                      </div>
                    </md-card-content>
                  </md-card>
                </div>
              </router-link>
            </v-col>
            <Pagination
              style="margin: -9px;"
              :current-page="currentPage"
              :total-count="totalCount"
              @load-page="loadPage"
              v-if="inquiries.length"
            />
          </v-row>
          <div v-if="loading" class="my-5">
            <md-progress-spinner class="color_stroke" md-mode="indeterminate"></md-progress-spinner>
          </div>
          <v-row v-if="!loading && inquiries.length === 0">
            <v-col cols="12">
              No styles found.
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import restAdapter from "../../restAdapter";
import auth from "../../auth";
import Pagination from "../../components/Pagination";
import * as conf from "../../conf.yml";
import debounce from "lodash/debounce";
import utils from "../../utils";

export default {
  name: "InquiryListInactive",

  components: {
    Pagination,
  },
  data: () => ({
    inquiries: [],
    loading: true,
    query: "",
    productType: null,
    productTypes: [],
    materials: [],
    sampleImage: require(`../../assets/shirt.png`),
    currentPage: 1,
    totalCount: 0,
    acceptedThumbnailTypes: utils.acceptedThumbnailTypes,
    inquiryStatus: "completed_or_inactive",
  }),
  mounted() {
    this.inquiries = [];
    this.loading = true;
    this.baseUrl = conf.default.server.host;
    this.getMaterials().then((response) => {
      this.materials = response.data.materials;
    });
    this.inquiryStatus = this.$route.query.status ? this.$route.query.status : "completed_or_inactive";
    this.productType = this.$route.query.product_type;
    this.query = this.$route.query.query ? this.$route.query.query : " ";

    this.getProductTypes().then((response) => {
      this.productTypes = response.data.productTypes;
      this.productTypes.unshift({ id: 0, name: "All" });
      this.getInquiries();
    });
  },
  methods: {
    isSupportedFileType(filename) {
      const extension =
        filename.match(/\.[0-9a-z]+$/i) && filename.match(/\.[0-9a-z]+$/i)[0];
      const supportedFileTypes = this.acceptedThumbnailTypes.split(",");
      return supportedFileTypes.includes(extension);
    },
    getProductTypes() {
      return restAdapter.get("/api/inquire_product_types");
    },
    getMaterials() {
      return restAdapter.get("/api/inquire_materials");
    },
    getIsAccountManager() {
      return auth.user.userType === "account_manager";
    },
    getIsCustomer() {
      return auth.user.userType === "customer";
    },
    getInquiries() {
      this.loading = true;
      this.$router.push({query: { "status": this.inquiryStatus, "product_type": this.productType, "query": this.query } });
      let url =
        "/api/inquiries?status=" +
        this.inquiryStatus +
        "&page=" +
        this.currentPage;
      url +=
        "&query=" +
        this.query +
        "&product_type=" +
        (this.productType ? this.productType : "");

      restAdapter.get(url).then((response) => {
        this.inquiries = response.data.data;
        this.totalCount = response.data.meta.total;
        this.loading = false;
      });
    },
    getProductTypeName(id) {
      const productType = this.productTypes.find(
        (productType) => productType.id == id
      );
      return productType && productType.name;
    },
    getMaterialName(id) {
      const material = this.materials.find((material) => material.id == id);
      return material && material.name;
    },
    loadPage(page) {
      this.currentPage = page;
      this.getInquiries();
    },
    clearFilters() {
      this.inquiryStatus = "completed_or_inactive";
      this.query = "";
      this.productType = null;
    },
    debounceSearch: debounce(function() {
      this.getInquiries();
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
.inquiry-list {
  .md-title,
  .md-subhead {
    text-align: left;
  }

  a:not(.md-button):hover {
    text-decoration: none;
  }

  .md-card.md-theme-default:hover {
    text-decoration: none;
    background-color: #f3f1f1;
  }

  .inquiry-quantity {
    text-align: left;
  }

  .inquiry-colors {
    text-align: left;
    padding-top: 5px;
  }

  .inquiry-quantity__value {
    font-size: 15px;
    font-weight: bold;
    color: #442D65;
  }

  .inquiry-sub-title{
    font-size: 15px;
    color: #442D65;
  }

  .md-card-actions.md-alignment-right {
    justify-content: space-between;
  }
}

.grid-2 {
  display: flex;
  flex-direction: row;
}

.inquiry-search-bar {
  text-align: right;
  justify-content: flex-end;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.search-content{
    margin-bottom: 40px;
    padding: 12px;
}

.search_field_class{
  margin: 0%;
}

.inquiry-style-number {
  color: #f3f1f1;
  text-align: left;
  font-family: sans-serif;
  font-weight: 500;
}

.header {
  background: #473068;
  height: calc(100% - 50%);
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.inquiry-product-type {
  color: #f3f1f1;
}

.date {
  color: #f3f1f1;
  text-align: right;
}

.paddings {
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 10px;
  padding-bottom: 8px;
  display: flex;
  justify-content: space-between;
}

.card-content {
    padding-bottom: 16px !important;
}

.embed {
  height: 50px;
}

.seperator {
  border-right: 1px solid #78909c;
}

.alignment {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .seperator {
    border-right: none;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .seperator {
    border-right: none;
    border-right: 10px solid #78909c;
  }
}

.card-content-item {
  padding-right: 40px !important;
  padding-left: 40px !important;
}

.centered-and-cropped {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.inquiry-list__card {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
  border-radius: 7px;
}

.inquiry-list__card:hover{
  background: #FFFFFF;
  box-shadow: 0px 17px 6px rgba(28, 45, 71, 0.15);
  border-radius: 7px;
  .header{
    background: #70D0CE;
    color: #473068;
  }
  .inquiry-style-number{
    color: #473068;
  }
  .inquiry-product-type{
    color: #473068;
  }
  .date{
    color: #473068;
  }
}

.color_class {
  border: 1px solid #473068;
  color: #442D65;
}

.color_class{
  color: #492a67 !important;
}

.qty_color{
  background-color: #c6a8ec !important;
}

.color_stroke::v-deep .md-progress-spinner-circle{
    stroke: #473068 !important;
}

.md-field.md-theme-default:before{
    background-color: #473068 !important;
}

.list_color.md-selected::v-deep .md-list-item-text{
    color: #473068 !important;
}

.md-field.md-theme-default.md-focused label{
  color: #442D65 !important;
}

.search_type_container{
  margin-top: -10px !important;
  background-color: #F3EDF7 !important;
  box-shadow: 3.19039px 14.8885px 12.7616px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}

.md-select-menu{
    top: 100px;
    border-radius: 64px;
    border: 10px solid;
}

.list_color.md-theme-default.md-selected{
  background-color: #E2DAED !important;
}

.img-container{
  align-items: center;
}

</style>
